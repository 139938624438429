import React from "react";
import { css, withTheme } from "@delight-js/react";
import ReactMarkdown from "react-markdown";
import Link from "./Link";
import { P, List, ListItem } from "./texts";

export const withTopMargin = (Component, options = {}) =>
  withTheme(({ theme, ...props }) => {
    let { node, className = "" } = props;
    const { big } = options;
    return (
      <Component
        className={className}
        css={
          node.position.start.offset > 0
            ? big
              ? css`$pt6;`(theme)
              : css`$pt2;`(theme)
            : {}
        }
        {...{
          ...props,
          className,
        }}
      />
    );
  });

const defaultComponents = {
  p: withTopMargin(({ node, ...props }) => <P {...props} />),
  ul: withTopMargin(({ node, depth, ordered, ...props }) => (
    <List {...props} />
  )),
  li: ({ node, index, ordered, checked, ...props }) => <ListItem {...props} />,
  a: ({ node, href, ...props }) => <Link url={href} {...props} />,
  strong: withTheme(({ node, theme, ...props }) => (
    <strong
      css={css`
  $textHeadline;
  color: inherit;
  `(theme)}
      {...props}
    />
  )),
  ...["h1", "h2", "h3", "h4", "h5", "h6"].reduce(
    (acc, Headline) => ({
      ...acc,
      [Headline]: withTopMargin(
        ({ node, level, ...props }) => <Headline {...props} />,
        { big: true }
      ),
    }),
    {}
  ),
  br: () => <br />,
};

const ParseMarkdown = ({ markdown, components = {} }) => {
  components = {
    ...defaultComponents,
    ...components,
  };
  return <ReactMarkdown children={markdown} components={components} />;
};

export default ParseMarkdown;
