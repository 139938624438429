import * as React from "react";
import { css, withTheme } from "@delight-js/react";
import PageHeader from "./PageHeader";
import { P2 } from "./texts";
import Image from "./Image";
import Grid from "./layout/Grid";
import Row from "./layout/Row";
import ContentCell from "./layout/ContentCell";
// import { Helmet } from "react-helmet";

const Hero = withTheme(
  ({
    theme,
    overline,
    headline,
    subline,
    sharingUrl,
    metaline = "",
    image,
  }) => {
    const { gatsbyImageData } = image?.childImageSharp;
    return (
      <React.Fragment>
        <PageHeader
          overline={overline}
          headline={headline}
          subline={subline}
          metaline={metaline}
          url={sharingUrl}
        />
        {!!gatsbyImageData && (
          <Image image={gatsbyImageData} alt={headline} loading="eager" />
        )}
        {!!metaline?.length && (
          <Grid
            css={css`
              height: 0;
            `}
          >
            <Row>
              <ContentCell
                css={css`
                  position: relative;
                  height: 0;
                `()}
              >
                <P2
                  css={css`
                    $mt1;
                    position: absolute;
                    left: calc($grid-gutter / 2);
                    top: calc($grid-gutter / 2);
                    font-size: 0.6em;
                    opacity: 0.4;
                    @media:md {
                      /* left: calc(100% + $grid-gutter / 2); */
                    }
                    @media:lg {
                      font-size: 12px;
                    }
                  `(theme)}
                >
                  {metaline}
                </P2>
              </ContentCell>
            </Row>
          </Grid>
        )}
      </React.Fragment>
    );
  }
);

export default Hero;
