import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { css, withTheme } from "@delight-js/react";
import Cookies from "js-cookie";
import Button from "./Button";
import Image from "./Image";

const YouTube = withTheme(
  ({ theme, videoId, title, cover, locale, ...props }) => {
    const {
      allTranslation: { edges },
    } = useStaticQuery(graphql`
      query YoutubeQuery {
        allTranslation(filter: { meta: { domain: { eq: "components" } } }) {
          edges {
            node {
              meta {
                language
              }
              data {
                youtube {
                  play
                  allowText
                  allowButton
                }
              }
            }
          }
        }
      }
    `);
    const { play, allowText, allowButton } =
      edges?.find((edge) => edge.node.meta.language === locale)?.node?.data
        ?.youtube || {};
    const [hasConsent, setHasConsent] = React.useState(
      Cookies.get("gatsby-gdpr-youtube")
    );
    const [shallPlay, setShallPlay] = React.useState(false);

    const allow = () => {
      Cookies.set("gatsby-gdpr-youtube", "true");
      setShallPlay(true);
      setHasConsent("true");
    };

    const handlePlay = () => setShallPlay(true);

    if (shallPlay) {
      return (
        <iframe
          src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1`}
          title={title}
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          {...props}
        ></iframe>
      );
    }
    const backgroundImage = cover
      ? ""
      : `url("https://i.ytimg.com/vi/${videoId}/hqdefault.jpg")`;
    return (
      <div
        css={css`
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background: no-repeat center;
          background-size: cover;

          &:after {
            content: "";
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.8);
            background: radial-gradient(
              circle,
              rgba(255, 255, 255, 1) 0%,
              rgba(255, 255, 255, 0.6) 46%
            );
          }
        `}
        style={{
          backgroundImage,
        }}
        {...props}
      >
        {!!cover && (
          <Image
            image={cover}
            alt=""
            css={css`
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 1;
            `}
          />
        )}
        <div
          css={css`
            position: relative;
            z-index: 2;
            text-align: center;
          `}
        >
          {hasConsent === "true" ? (
            <Button onClick={handlePlay}>{play}</Button>
          ) : (
            <React.Fragment>
              <svg height="48" version="1.1" viewBox="0 0 68 48" width="68">
                <path
                  d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                  fill="#f00"
                ></path>
                <path d="M 45,24 27,14 27,34" fill="#fff"></path>
              </svg>
              <div css={css`$mt2;`(theme)}>{allowText}</div>
              <Button onClick={allow} css={css`$mt4;`(theme)} small>
                {allowButton}
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
);

export default YouTube;
