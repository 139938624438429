import React from "react";
import Cell from "./Cell";

const ContentCell = ({ children, ...props }) => (
  <Cell
    span={{ default: 10 / 12, md: 8 / 12, x2l: 6 / 12 }}
    offset={{ default: 1 / 12, md: 2 / 12, x2l: 3 / 12 }}
    {...props}
  >
    {children}
  </Cell>
);

export default ContentCell;
